import React from "react"
import { Link } from "gatsby"
import { ScheduleProvider } from "../../ScheduleContext"
import AdminLayout from "../../components/adminPanelLayout"
import MainScheduleComponent from "../../components/schedule/MainScheduleComponent"
import SEO from "../../components/seo"

const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <ScheduleProvider>
      <MainScheduleComponent></MainScheduleComponent>
    </ScheduleProvider>
  </AdminLayout>
)

export default AdminPanel
