import React, { useContext, useEffect, useState } from "react"
import Schedule from "./ContextScheduleComponent"
import ChooseObjectComponent from "./ChooseObjectComponent"
import { sendRequest } from "../../services/requestTool"
import DefaultLoader from "../shared/DefaultLoaderComponent"
import { ScheduleContext, ScheduleActionsContext } from "../../ScheduleContext"
import moment from "moment"
import styled from "styled-components"
import MainButton from "../shared/MainButtonComponent"

const CurrentObjectInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ScheduleForWord = styled.div`
  font-weight: bold;
`
const DatePickerNavWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  justify-content: center;
  margin-bottom: 30px;
`

const DatePickerButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
`

const DatePickerMonthWrapper = styled.div`
  min-width: 250px;
  text-align: center;
`

export default function MainScheduleComponent() {
  const scheduleData = useContext(ScheduleContext)
  const scheduleActions = useContext(ScheduleActionsContext)
  const [currentActiveDate, setCurrentActiveDate] = useState(
    new Date(2029, 0, 1)
  )
  const [selectedSalon, setSelectedSalon] = useState(null)
  const [isMoreThanOneSalon, setIsMoreThanOneSalon] = useState(null)
  const onDateIncrement = () => {
    const date = moment(currentActiveDate)
    date.add(1, "month")
    setCurrentActiveDate(date.toDate())
  }

  const onDateDecrement = () => {
    const date = moment(currentActiveDate)
    date.add(-1, "month")
    setCurrentActiveDate(date.toDate())
  }

  useEffect(() => {
    if (!selectedSalon) return
    // TODO: dodac id obiektu przy zapytaniu o callendar
    scheduleActions.getData(currentActiveDate)
  }, [currentActiveDate, selectedSalon])
  const onSalonChoose = salon => {
    setSelectedSalon(salon)
  }

  const setSalonsMultiple = multiple => {
    setIsMoreThanOneSalon(multiple)
  }

  const onChangeSalonClick = () => {
    setSelectedSalon(null)
  }
  return (
    <div>
      {selectedSalon ? (
        <div>
          <CurrentObjectInfoWrapper>
            <ScheduleForWord>Grafik dla obiektu:</ScheduleForWord>
            {selectedSalon.city}, {selectedSalon.address}
            {isMoreThanOneSalon && (
              <MainButton
                onClick={onChangeSalonClick}
                fontSize={12}
                padding="5px 10px"
                text="Zmień salon"
                borderThickness={1}
              ></MainButton>
            )}
          </CurrentObjectInfoWrapper>
          <DatePickerNavWrapper>
            <DatePickerButton onClick={onDateDecrement}>&lt;</DatePickerButton>
            <DatePickerMonthWrapper>
              {moment(currentActiveDate).format("MMMM YYYY")}{" "}
            </DatePickerMonthWrapper>
            <DatePickerButton onClick={onDateIncrement}>&gt;</DatePickerButton>
          </DatePickerNavWrapper>
          {scheduleData ? (
            <Schedule daysInMonth={scheduleData}></Schedule>
          ) : (
            <DefaultLoader desc={"Ładowanie..."}></DefaultLoader>
          )}
        </div>
      ) : (
        <ChooseObjectComponent
          setSalonsMultiple={setSalonsMultiple}
          salonChooseCallback={onSalonChoose}
        ></ChooseObjectComponent>
      )}
    </div>
  )
}
