import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SingleDayColumn from "./SingleDayColumnComponent.js"

const EmployeesWorksDaysWrapper = styled.div`
  display: flex;
`

export default function EmployeesWorksDaysComponent({
  daysInCurrentMonthArray,
  cellSize,
  weekdayStyle,
}) {
  return (
    <EmployeesWorksDaysWrapper>
      {daysInCurrentMonthArray.map((e, i) => {
        return (
          <SingleDayColumn
            cellSize={cellSize}
            dayData={{ date: e.date, workerSmallDetails: e.workerSmallDetails }}
            key={i}
            weekdayStyle={(function () {
              if (
                new Date(e.date).getDay() == 0 ||
                new Date(e.date).getDay() == 6
              ) {
                return weekdayStyle
              }
            })()}
          ></SingleDayColumn>
        )
      })}
    </EmployeesWorksDaysWrapper>
  )
}
