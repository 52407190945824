import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ScheduleDayCell from "./ScheduleDayCellComponent"
import { v4 as uuidv4 } from "uuid"

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 1px solid rgba(33, 33, 33, 0.4);
  &:last-of-type {
    border: none;
  }
`
export default function SingleDayColumnComponent({
  dayData,
  cellSize,
  weekdayStyle,
}) {
  return (
    <ColumnWrapper style={{ minWidth: cellSize.width + "px" }}>
      {dayData
        ? dayData.workerSmallDetails.map((e, i) => {
            e.uuid = uuidv4()
            return (
              <ScheduleDayCell
                key={e.uuid + e.workingHours.length}
                cellSize={cellSize}
                date={dayData.date}
                config={e}
                weekdayStyle={weekdayStyle}
              ></ScheduleDayCell>
            )
          })
        : ""}
    </ColumnWrapper>
  )
}
