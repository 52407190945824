import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import moment from "moment"
import { ScheduleContext, ScheduleActionsContext } from "../../ScheduleContext"
import SingleHourSelectComponent from "./SingleHourSelectComponent"

const DayCell = styled.div`
  border-bottom: 1px solid rgba(33, 33, 33, 0.4);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const DayCellButton = styled.button`
  background-color: #fff;
  border: 1px solid #000;
  outline: none;
`

export default function ScheduleDayCellComponent({
  config,
  cellSize,
  date,
  weekdayStyle,
}) {
  const schedule = useContext(ScheduleContext)
  const scheduleActions = useContext(ScheduleActionsContext)
  const [currentCellHours, setCurrentCellHours] = useState([])
  const [dataVisible, setCellDataVisibility] = useState(true)
  const [cellOnEdit, setCellEditState] = useState(false)
  const [changesInCell, setChangesInCell] = useState(false)

  const onStartInputChange = (event, hoursIndex) => {
    setCurrentCellHours(
      currentCellHours.map((e, i) => {
        if (hoursIndex == i) {
          e.start = event.target.value
        }
        return e
      })
    )
  }

  const toggleEditableMode = () => {
    const currentState = cellOnEdit
    setCellEditState(!currentState)
  }

  const onCellSaveClick = config => {
    toggleEditableMode()
    scheduleActions.saveChanges(date, config.name, currentCellHours)
  }

  const checkIfDataChanged = () => {
    const areChangesInCell =
      JSON.stringify(currentCellHours) != JSON.stringify(config.workingHours)
    setChangesInCell(areChangesInCell)
    scheduleActions.cellChangesListener(date, config.name, areChangesInCell)
  }

  useEffect(() => {
    if (config.workingHours[0]) {
      setCurrentCellHours(
        config.workingHours.map(e => {
          return {
            start: moment()
              .set("minute", e.startMinutes)
              .set("hour", e.startHours)
              .toDate(),
            end: moment()
              .set("minute", e.finishMinutes)
              .set("hour", e.finishHours)
              .toDate(),
          }
        })
      )
    } else {
      setCurrentCellHours([])
    }
    setCellDataVisibility(true)
  }, [])

  return (
    <div>
      {dataVisible && (
        <DayCell
          style={{
            height: cellSize.height,
            maxHeight: cellSize.height,
            ...weekdayStyle,
          }}
        >
          {schedule
            .filter(x => moment(x.date).isSame(date, "day"))[0]
            .workerSmallDetails.filter(w => w.name == config.name)[0]
            .workingHours.map((elem, i) => {
              return (
                <div key={i}>
                  {cellOnEdit ? (
                    <div>
                      <SingleHourSelectComponent
                        onTimeChangeCallback={newDate => {
                          currentCellHours[i].start = newDate
                          checkIfDataChanged()
                        }}
                        timeValue={
                          elem.startHours
                            ? moment()
                                .set("minute", elem.startMinutes)
                                .set("hour", elem.startHours)
                                .toDate()
                            : ""
                        }
                      />
                      -
                      <SingleHourSelectComponent
                        onTimeChangeCallback={newDate => {
                          currentCellHours[i].end = newDate
                          checkIfDataChanged()
                        }}
                        timeValue={
                          elem.finishHours
                            ? moment()
                                .set("minute", elem.finishMinutes)
                                .set("hour", elem.finishHours)
                                .toDate()
                            : ""
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      {moment()
                        .set("minute", elem.startMinutes)
                        .set("hour", elem.startHours)
                        .format("HH:mm")}
                      -
                      {moment()
                        .set("minute", elem.finishMinutes)
                        .set("hour", elem.finishHours)
                        .format("HH:mm")}
                    </div>
                  )}

                  {/* <input
                    style={{ paddingLeft: "10px", color: "red" }}
                    type="text"
                    value={currentCellHours[i].start.hour}
                    onChange={event => onStartInputChange(event, i)}
                  /> */}
                  {/* {moment(elem.start).format("hh:mm") +
                "-" +
                moment(elem.end).format("hh:mm")} */}
                </div>
              )
            })}
          {changesInCell && <div>niezapisane zmiany</div>}
          <div>
            <DayCellButton
              onClick={() =>
                scheduleActions.updateSchedule(date, { name: config.name })
              }
            >
              +
            </DayCellButton>
            <DayCellButton onClick={toggleEditableMode}>edit</DayCellButton>
            <DayCellButton onClick={() => onCellSaveClick(config)}>
              save
            </DayCellButton>
          </div>
        </DayCell>
      )}
    </div>
  )
}
