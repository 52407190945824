import React, { useEffect, useState } from "react"
import moment from "moment"
import styled from "styled-components"
import "rc-time-picker/assets/index.css"
import TimePicker from "rc-time-picker"

const SingleHourSelectWrapper = styled.div`
  display: flex;
`

export default function SingleHourSelectComponent({
  timeValue,
  onTimeChangeCallback,
}) {
  // const [currentSelectHour, setCurrentCellHour] = useState("")
  // const [currentSelectMin, setCurrentCellMin] = useState("")

  // useEffect(() => {
  //   setCurrentCellHour(moment(timeValue).format("HH"))
  //   setCurrentCellMin(moment(timeValue).format("mm"))
  // }, [])

  // const onHourInputChange = event => {
  //   setCurrentCellHour(event.target.value)
  // }
  // const onMinInputChange = event => {
  //   setCurrentCellMin(event.target.value)
  // }
  // TODO: nie moze byc podzialu na dwa selecty (godz i min) - musi byc jeden datepicker
  return (
    <SingleHourSelectWrapper>
      <TimePicker
        style={{ width: "70px" }}
        showSecond={false}
        defaultValue={moment(timeValue)}
        className="xxx"
        onChange={value => {
          onTimeChangeCallback(value.toDate())
        }}
      />
      {/* <input
        style={{ width: "50px", textAlign: "center" }}
        type="text"
        value={currentSelectHour}
        onChange={onHourInputChange}
      />
      <input
        style={{ width: "50px", textAlign: "center" }}
        type="text"
        value={currentSelectMin}
        onChange={onMinInputChange}
      /> */}
    </SingleHourSelectWrapper>
  )
}
