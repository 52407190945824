import React, { useContext } from "react"
import moment from "moment"
import styled from "styled-components"
import "moment/locale/pl"
moment.locale("pl")

const DayNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(33, 33, 33, 0.6);
  align-items: center;

  &:last-of-type {
    border: none;
  }
`
export default function DayCellHeaderComponent({ config }) {
  return (
    <DayNameWrapper
      style={{ width: config.columnWidth + "px", ...config.weekdayStyle }}
    >
      <div> {moment(config.date).format("DD.MM.YYYY")}</div>
      <div> {moment(config.date).format("dddd")}</div>
    </DayNameWrapper>
  )
}
