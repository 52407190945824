import React, { useState, useEffect } from "react"
import { sendRequest } from "../../services/requestTool"
import { getObjects } from "../../services/api"
import styled from "styled-components"
import SingleSalonComponent from "../shared/SingleObjectComponent"
import DefaultLoaderComponent from "../shared/DefaultLoaderComponent"

const SalonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const ChooseObjectWrapper = styled.div``
const ChooseObjectTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
  font-size: 20px;
`

export default function ChooseObjectComponent({
  salonChooseCallback,
  setSalonsMultiple,
}) {
  const [availableObjects, setObjects] = useState(null)
  useEffect(() => {
    console.log("here")
    getObjects()
      .then(res => {
        console.log("then")
        if (res.data.length == 1) {
          salonChooseCallback(res.data[0])
          setSalonsMultiple(false)
          return
        }
        setSalonsMultiple(true)
        setObjects(res.data)
      })
      .catch(err => {})
  }, [])

  const onSalonChoose = e => {
    salonChooseCallback(e)
  }
  return (
    <>
      {availableObjects ? (
        <ChooseObjectWrapper>
          <ChooseObjectTitle>Wybierz obiekt</ChooseObjectTitle>
          <SalonsWrapper>
            {availableObjects.map((e, i) => {
              return (
                <SingleSalonComponent
                  imageHeight={150}
                  imageWidth={220}
                  buttonText="Wybierz"
                  clickCallback={() => onSalonChoose(e)}
                  salon={e}
                  key={i}
                ></SingleSalonComponent>
              )
            })}
          </SalonsWrapper>
        </ChooseObjectWrapper>
      ) : (
        <DefaultLoaderComponent />
      )}
    </>
  )
}
