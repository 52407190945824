import React from "react"
import styled from "styled-components"
import DayCellHeaderComponent from "./DayCellHeaderComponent"
import EmployeesWorksDaysComponent from "./EmployeesWorksDaysComponent"

const Schedule = styled.div`
  display: flex;
  border: 1px solid rgba(33, 33, 33, 0.6);
`
const EmployeesWrapper = styled.div``

const DaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-left: 1px solid rgba(33, 33, 33, 0.6);
`
const DaysHeader = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(33, 33, 33, 0.6);
  width: max-content;
`

const weekdayStyle = {
  backgroundColor: "rgba(133,133,133,.1)",
}
const scheduleCellSize = {
  width: 250,
  height: 140,
}

export default function ContextScheduleComponent({ daysInMonth }) {
  return (
    <Schedule>
      <EmployeesWrapper>employees</EmployeesWrapper>
      <DaysWrapper>
        <DaysHeader>
          {daysInMonth.map((e, i) => {
            return (
              <DayCellHeaderComponent
                key={i}
                config={{
                  ...e,
                  columnWidth: scheduleCellSize.width,
                  weekdayStyle: (function () {
                    if (
                      new Date(e.date).getDay() == 0 ||
                      new Date(e.date).getDay() == 6
                    ) {
                      return weekdayStyle
                    }
                  })(),
                }}
              />
            )
          })}
        </DaysHeader>
        <EmployeesWorksDaysComponent
          daysInCurrentMonthArray={daysInMonth}
          cellSize={scheduleCellSize}
          weekdayStyle={weekdayStyle}
        ></EmployeesWorksDaysComponent>
      </DaysWrapper>
    </Schedule>
  )
}
